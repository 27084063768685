<template>
  <pdf src="../../public/static/Eli_Collinson_Resume.pdf"></pdf>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'Resume',
  components: {
    pdf
  }
}
</script>

<style>

</style>
